import { Routes, Route} from 'react-router-dom'
import Header from "./components/layout/Header"
import Footer from "./components/layout/Footer"
import Top from './pages/Top'
import Photography from './pages/photography/Photography'
import SchottDickies from './pages/photography/SchottDickies'
import SchottWrangler from './pages/photography/SchottWrangler'
import OverAllTokyo from './pages/photography/OverAllTokyo'
import SternbergSS from './pages/photography/SternbergSS'
import LimTokyo from './pages/photography/LimTokyo'
import Miske from './pages/photography/Miske'
import Pmd from './pages/photography/Pmd'
import Dishup from './pages/photography/Dishup'
import MyWork from './pages/photography/MyWork'
import Commercial from './pages/videos/commercial/Commercial'
import Fendi from './pages/videos/commercial/Fendi'
import SuitCompany from './pages/videos/commercial/SuitCompany'
import Uniqlo from './pages/videos/commercial/Uniqlo'
import Gtune from './pages/videos/commercial/Gtune'
import Woods2021FW from './pages/videos/commercial/Woods2021FW'
import KeirinGp from './pages/videos/commercial/KeirinGp'
import Puma from './pages/videos/commercial/Puma'
import Amostyle from './pages/videos/commercial/Amostyle'
import Tatras from './pages/videos/commercial/Tatras'
import NikeBeyond from './pages/videos/commercial/NikeBeyond'
import MusicVideo from './pages/videos/music-video/MusicVideo'
import Ryokushaka from './pages/videos/music-video/Ryokushaka'
import Dish from './pages/videos/music-video/Dish'
import DishSecond from './pages/videos/music-video/DishSecond'
import DishThird from './pages/videos/music-video/DishThird'
import RiraIkuta from './pages/videos/music-video/RiraIkuta'
import Chilldspot from './pages/videos/music-video/Chilldspot'
import Chai from './pages/videos/music-video/Chai'
import ThePeggies from './pages/videos/music-video/ThePeggies'
import Maliya from './pages/videos/music-video/Maliya'
import TokyoRaveGroupie from './pages/videos/music-video/TokyoRaveGroupie'
import MireiToyama from './pages/videos/music-video/MireiToyama'
import Ao from './pages/videos/music-video/Ao'
import Kanaboon from './pages/videos/music-video/Kanaboon'
import Atarayo from './pages/videos/music-video/Atarayo'
import Cent from './pages/videos/music-video/Cent'
import Nogizaka46 from './pages/videos/music-video/Nogizaka46'
import Mmnc from './pages/videos/music-video/Mmnc'
import Wacoal from './pages/videos/commercial/Wacoal'
// import PlatinumWoman from './pages/videos/commercial/PlatinumWoman'
import TokyoTushin from './pages/videos/commercial/TokyoTushin'
import Vans from './pages/videos/commercial/Vans'
import EnsembleStars from './pages/videos/commercial/EnsembleStars'
import Zozovilla from './pages/videos/commercial/Zozovilla'
import LippsBoy from './pages/videos/commercial/LippsBoy'
import TomooFirst from './pages/videos/music-video/TomooFirst'
import ChilliBeans from './pages/videos/music-video/ChilliBeans'
import BadHop from './pages/videos/music-video/BadHop'
import DishFourth from './pages/videos/music-video/DishFourth'
import Nulbarich from './pages/videos/music-video/Nulbarich'
import GoGoVanillas from './pages/videos/music-video/GoGoVanillas'
import OfficialHigeDandism from './pages/videos/music-video/OfficialHigeDandism'
import SkyHiNissy from './pages/videos/music-video/SkyHiNissy'
import TomooSecond from './pages/videos/music-video/TomooSecond'
import TatsuroYamashita from './pages/videos/music-video/TatsuroYamashita'
import CasioGShock from './pages/videos/commercial/CasioGShock'
import Jurk from './pages/videos/commercial/Jurk'

function App() {
  return (
    <>
      <div className='wrapper w-4/5 my-0 mx-auto'>
        <Header />
        <Routes>
          <Route path='/' element={<Top />} index></Route>
          <Route path='/photography' element={<Photography />}></Route>
          <Route path='/photo'>
            <Route path='schott-dickies' element={<SchottDickies />}></Route>
            <Route path='schott-wrangler' element={<SchottWrangler />}></Route>
            <Route path='overalltokyo' element={<OverAllTokyo />}></Route>
            <Route path='sternberg-ss' element={<SternbergSS />}></Route>
            <Route path='limtokyo' element={<LimTokyo />}></Route>
            <Route path='miske' element={<Miske />}></Route>
            <Route path='pmd' element={<Pmd />}></Route>
            <Route path='dishup' element={<Dishup />}></Route>
            <Route path='my-work' element={<MyWork />}></Route>
          </Route>
          <Route path='/video'>
            <Route path='commercial' element={<Commercial />}></Route>
            <Route path='commercial/fendi' element={<Fendi />}></Route>
            <Route path='commercial/the-suit-company' element={<SuitCompany />}></Route>
            <Route path='commercial/gtune-detonation-gaming' element={<Gtune />}></Route>
            <Route path='commercial/uniqlo-2022fw' element={<Uniqlo />}></Route>
            <Route path='commercial/woods-2021FW' element={<Woods2021FW />}></Route>
            <Route path='commercial/keirin-gp-2022' element={<KeirinGp />}></Route>
            <Route path='commercial/puma' element={<Puma />}></Route>
            <Route path='commercial/amostyle' element={<Amostyle />}></Route>
            <Route path='commercial/tatras' element={<Tatras />}></Route>
            <Route path='commercial/nike-beyond' element={<NikeBeyond />}></Route>
            <Route path='commercial/wacoal' element={<Wacoal />}></Route>
            {/* <Route path='commercial/platinum-woman' element={<PlatinumWoman />}></Route> */}
            <Route path='commercial/tokyo-tushin' element={<TokyoTushin />}></Route>
            <Route path='commercial/vans' element={<Vans />}></Route>
            <Route path='commercial/ensemble-stars' element={<EnsembleStars />}></Route>
            <Route path='commercial/zozovilla' element={<Zozovilla />}></Route>
            <Route path='commercial/lipps-boy' element={<LippsBoy />}></Route>
            <Route path='commercial/casio-g-shock' element={<CasioGShock />}></Route>
            <Route path='commercial/jurk' element={<Jurk />}></Route>
            <Route path='music-video' element={<MusicVideo />}></Route>
            <Route path='music-video/ryokuoushokushakai' element={<Ryokushaka />}></Route>
            <Route path='music-video/dish/chinchouge' element={<Dish />}></Route>
            <Route path='music-video/dish/goasatte' element={<DishSecond />}></Route>
            <Route path='music-video/dish/banbanzai' element={<DishThird />}></Route>
            <Route path='music-video/ikutarira' element={<RiraIkuta />}></Route>
            <Route path='music-video/chilldspot' element={<Chilldspot />}></Route>
            <Route path='music-video/chai' element={<Chai />}></Route>
            <Route path='music-video/the-peggies' element={<ThePeggies />}></Route>
            <Route path='music-video/maliya' element={<Maliya />}></Route>
            <Route path='music-video/tokyoravegroupie' element={<TokyoRaveGroupie />}></Route>
            <Route path='music-video/toyamamirei' element={<MireiToyama />}></Route>
            <Route path='music-video/ao' element={<Ao />}></Route>
            <Route path='music-video/kana-boon' element={<Kanaboon />}></Route>
            <Route path='music-video/atarayo' element={<Atarayo />}></Route>
            <Route path='music-video/cent' element={<Cent />}></Route>
            <Route path='music-video/nogizaka46' element={<Nogizaka46 />}></Route>
            <Route path='music-video/mmnc' element={<Mmnc />}></Route>
            <Route path='music-video/tomoo/present' element={<TomooFirst />}></Route>
            <Route path='music-video/chillibeans' element={<ChilliBeans />}></Route>
            <Route path='music-video/badhop' element={<BadHop />}></Route>
            <Route path='music-video/dish/itsudatte-high' element={<DishFourth />}></Route>
            <Route path='music-video/nulbarich' element={<Nulbarich />}></Route>
            <Route path='music-video/go-go-vanillas' element={<GoGoVanillas />}></Route>
            <Route path='music-video/official-hige-dan-dism' element={<OfficialHigeDandism />}></Route>
            <Route path='music-video/skyhi-nissy' element={<SkyHiNissy />}></Route>
            <Route path='music-video/tomoo/superball' element={<TomooSecond />}></Route>
            <Route path='music-video/tatsuro-yamashita/sparkle' element={<TatsuroYamashita />}></Route>
          </Route>
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
