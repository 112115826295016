import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/jurk_concept_movie/IMG_0277.png'
import secondPhoto from '../../../assets/video/jurk_concept_movie/IMG_0278.png'
import thirdPhoto from '../../../assets/video/jurk_concept_movie/IMG_0279.png'
import fourthPhoto from '../../../assets/video/jurk_concept_movie/IMG_0280.png'
import fifthPhoto from '../../../assets/video/jurk_concept_movie/IMG_0281.png'
import sixthPhoto from '../../../assets/video/jurk_concept_movie/IMG_0282.png'
import seventhPhoto from '../../../assets/video/jurk_concept_movie/IMG_0283.png'
import eighthPhoto from '../../../assets/video/jurk_concept_movie/IMG_0285.png'

function Jurk() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ jurk concept movie - each daily life</h2>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        jurk concept movie - each daily life<br />
                        <br />
                        Director: Tomokazu Saito<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/tokyo-tushin' />
        </>
    )
}

export default Jurk