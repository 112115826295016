import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/gtune/gtune_002.png'
import secondPhoto from '../../../assets/video/gtune/gtune_001.png'
import thirdPhoto from '../../../assets/video/gtune/gtune_003.png'
import fourthPhoto from '../../../assets/video/gtune/gtune_004.png'

function Gtune() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ The e-sports colosseum</h2>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        G-Tune × DetonatioN Gaming<br />
                        <br />
                        ~ web CM ~<br />
                        <br />
                        DIRECTOR: Asuya Hamada<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/lipps-boy' />
        </>
    )
}

export default Gtune