import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/zozovilla/IMG_0089.png'
import secondPhoto from '../../../assets/video/zozovilla/IMG_0090.png'
import thirdPhoto from '../../../assets/video/zozovilla/IMG_0091.png'
import fourthPhoto from '../../../assets/video/zozovilla/IMG_0092.png'
import fifthPhoto from '../../../assets/video/zozovilla/IMG_0094.png'
import sixthPhoto from '../../../assets/video/zozovilla/IMG_0095.png'
import seventhPhoto from '../../../assets/video/zozovilla/IMG_0096.png'
import eighthPhoto from '../../../assets/video/zozovilla/IMG_0097.png'

function Zozovilla() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ ZOZOVILLA - New luck? New Wallet.</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/3JGtC4H7Elw?si=tl5FtoU4N3CJOGfz" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        ZOZOVILLA - New luck? New Wallet.<br />
                        <br />
                        Director: Yuka Yamaguchi<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/tokyo-tushin' />
        </>
    )
}

export default Zozovilla