import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/wacoal/IMG_0041.png'
import secondPhoto from '../../../assets/video/wacoal/IMG_0042.png'
import thirdPhoto from '../../../assets/video/wacoal/IMG_0043.png'
import fourthPhoto from '../../../assets/video/wacoal/IMG_0045.png'
import fifthPhoto from '../../../assets/video/wacoal/IMG_0046.png'
import sixthPhoto from '../../../assets/video/wacoal/IMG_0047.png'
import seventhPhoto from '../../../assets/video/wacoal/IMG_0049.png'

function Wacoal() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ Wacoal - TV CM</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/T5hJfJXgQ1M?si=sWO2Am3BDYk5WbhU" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        Wacoal - TV CM<br />
                        <br />
                        Director: Yuka Yamaguchi<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/nike-beyond' />
        </>
    )
}

export default Wacoal