import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/badhop/IMG_0200.png'
import secondPhoto from '../../../assets/video/badhop/IMG_0201.png'
import thirdPhoto from '../../../assets/video/badhop/IMG_0202.png'
import fourthPhoto from '../../../assets/video/badhop/IMG_0203.png'
import fifthPhoto from '../../../assets/video/badhop/IMG_0204.png'
import sixthPhoto from '../../../assets/video/badhop/IMG_0205.png'
import seventhPhoto from '../../../assets/video/badhop/IMG_0206.png'
import eighthPhoto from '../../../assets/video/badhop/IMG_0207.png'

function BadHop() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ BAD HOP - Last Party Never End</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/NcMfwZ8AgRI?si=0PQWK7A2neDskR0l" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        BAD HOP - Last Party Never End<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Masaki Watanabe<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/chillibeans' />
        </>
    )
}

export default BadHop