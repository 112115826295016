function Photo(props) {
    return (
        <>
            <div className="photo">
                <img src={ props.src } alt="" />
            </div>
        </>
    )
}

export default Photo