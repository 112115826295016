import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/official_hige_dan_dism/IMG_0235.png'
import secondPhoto from '../../../assets/video/official_hige_dan_dism/IMG_0237.png'
import thirdPhoto from '../../../assets/video/official_hige_dan_dism/IMG_0238.png'
import fourthPhoto from '../../../assets/video/official_hige_dan_dism/IMG_0239.png'
import fifthPhoto from '../../../assets/video/official_hige_dan_dism/IMG_0241.png'
import sixthPhoto from '../../../assets/video/official_hige_dan_dism/IMG_0242.png'
import seventhPhoto from '../../../assets/video/official_hige_dan_dism/IMG_0243.png'

function OfficialHigeDandism() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ Official髭男dism - 日常</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/LbtQM793jn8?si=6GQSB5zougGgHpur" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        Official髭男dism - 日常<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Denki Imahara<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/chilldspot' />
        </>
    )
}

export default OfficialHigeDandism