import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/rira_ikuta/rira_ikuta_1.png'
import secondPhoto from '../../../assets/video/rira_ikuta/rira_ikuta_2.png'
import thirdPhoto from '../../../assets/video/rira_ikuta/rira_ikuta_3.png'
import fourthPhoto from '../../../assets/video/rira_ikuta/rira_ikuta_4.png'
import fifthPhoto from '../../../assets/video/rira_ikuta/rira_ikuta_5.png'
import sixthPhoto from '../../../assets/video/rira_ikuta/rira_ikuta_6.png'
import seventhPhoto from '../../../assets/video/rira_ikuta/rira_ikuta_7.png'
import eighthPhoto from '../../../assets/video/rira_ikuta/rira_ikuta_8.png'

function RiraIkuta() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ スパークル</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/q4CbHfW3Ji8" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        幾田りら - スパークル<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        DIRCTOR: Yuka Yamaguchi<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/dish/itsudatte-high' />
        </>
    )
}

export default RiraIkuta