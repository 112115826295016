import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/dish/dish_01.jpg'
import secondPhoto from '../../../assets/video/dish/dish_02.jpg'
import thirdPhoto from '../../../assets/video/dish/dish_03.jpg'
import fourthPhoto from '../../../assets/video/dish/dish_04.jpg'

function Dish() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ 沈丁花</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/TkHcaAVQoiI" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        DISH// - 沈丁花<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        CREATIVE DIRECTOR: Raita Nakamura<br />
                        DIRECTOR: Yuka Yamaguchi<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/toyamamirei' />
        </>
    )
}

export default Dish