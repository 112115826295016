import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/amostyle/amostyle_01.jpg'
import secondPhoto from '../../../assets/video/amostyle/amostyle_02.jpg'
import thirdPhoto from '../../../assets/video/amostyle/amostyle_03.jpg'
import fourthPhoto from '../../../assets/video/amostyle/amostyle_04.jpg'
import fifthPhoto from '../../../assets/video/amostyle/amostyle_05.jpg'
import sixthPhoto from '../../../assets/video/amostyle/amostyle_06.jpg'
import seventhPhoto from '../../../assets/video/amostyle/amostyle_07.jpg'
import eighthPhoto from '../../../assets/video/amostyle/amostyle_08.jpg'

function Amostyle() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ AMOSTYLE SS23 PV</h2>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        AMOSTYLE SS23 PV<br />
                        <br />
                        Director : Hideyuki Ishii<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/keirin-gp-2022' />
        </>
    )
}

export default Amostyle