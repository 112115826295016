import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/suit_company/suit_company_1.png'
import secondPhoto from '../../../assets/video/suit_company/suit_company_2.png'
import thirdPhoto from '../../../assets/video/suit_company/suit_company_3.png'
import fourthPhoto from '../../../assets/video/suit_company/suit_company_4.png'
import fifthPhoto from '../../../assets/video/suit_company/suit_company_5.png'
import sixthPhoto from '../../../assets/video/suit_company/suit_company_6.png'
import seventhPhoto from '../../../assets/video/suit_company/suit_company_7.png'
import eighthPhoto from '../../../assets/video/suit_company/suit_company_8.png'

function SuitCompany() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ THE SUIT COMPANY - 期待しない息子</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/XFLCkO1lFJY" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        THE SUIT COMPANY - 期待しない息子<br />
                        <br />
                        DIRCTOR : Tomokazu Saito<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/woods-2021fw' />
        </>
    )
}

export default SuitCompany