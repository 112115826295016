import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/woods/woods_01.png'
import secondPhoto from '../../../assets/video/woods/woods_04.png'
import thirdPhoto from '../../../assets/video/woods/woods_03.png'
import fourthPhoto from '../../../assets/video/woods/woods_02.png'

function Woods2021FW() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ WOODS - 2021 FW</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/kRXaDdIXVzQ" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        WOODS - 2021 FW<br />
                        <br />
                        ~ Look Video ~<br />
                        <br />
                        ART DIRECTOR: Yuji Nakata<br />
                        DIRECTOR: Asuya Hamada<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/tatras' />
        </>
    )
}

export default Woods2021FW