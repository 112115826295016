import Pagination from '../../components/layout/Pagination'
import Photo from '../../components/Photo'
import firstPhoto from '../../assets/photo/schott-dickies/schott-dickies-01.jpg'
import secondPhoto from '../../assets/photo/schott-dickies/schott-dickies-02.jpg'
import thirdPhoto from '../../assets/photo/schott-dickies/schott-dickies-03.jpg'
import fourthPhoto from '../../assets/photo/schott-dickies/schott-dickies-04.jpg'
import fifthPhoto from '../../assets/photo/schott-dickies/schott-dickies-05.jpg'
import sixthPhoto from '../../assets/photo/schott-dickies/schott-dickies-06.jpg'

function SchottDickies() {
    return (
        <>
            <div className="contents-wrapper">
                <h2>#Schott × Dickies</h2>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                    </div>
                </div>
            </div>
            <Pagination nextLink='/photo/schott-wrangler' />
        </>
    )
}

export default SchottDickies