import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/ryokushaka/ryokushaka_1.png'
import secondPhoto from '../../../assets/video/ryokushaka/ryokushaka_2.png'
import thirdPhoto from '../../../assets/video/ryokushaka/ryokushaka_3.png'
import fourthPhoto from '../../../assets/video/ryokushaka/ryokushaka_4.png'
import fifthPhoto from '../../../assets/video/ryokushaka/ryokushaka_5.png'
import sixthPhoto from '../../../assets/video/ryokushaka/ryokushaka_6.png'
import seventhPhoto from '../../../assets/video/ryokushaka/ryokushaka_7.png'
import eighthPhoto from '../../../assets/video/ryokushaka/ryokushaka_8.png'

function Ryokushaka() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ ミチヲユケ</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/su5o63BnOUM" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        緑黄色社会 - ミチヲユケ<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        DIRCTOR: Jun Tamukai<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/chai' />
        </>
    )
}

export default Ryokushaka