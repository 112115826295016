import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/cent/cent_01.jpg'
import secondPhoto from '../../../assets/video/cent/cent_02.jpg'
import thirdPhoto from '../../../assets/video/cent/cent_03.jpg'
import fourthPhoto from '../../../assets/video/cent/cent_04.jpg'
import fifthPhoto from '../../../assets/video/cent/cent_05.jpg'
import sixthPhoto from '../../../assets/video/cent/cent_06.jpg'
import seventhPhoto from '../../../assets/video/cent/cent_07.jpg'
import eighthPhoto from '../../../assets/video/cent/cent_08.jpg'

function Cent() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ すてきな予感</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/ehfxx6Q33kg" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        CENT - すてきな予感<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Denki Imahara<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/mmnc' />
        </>
    )
}

export default Cent