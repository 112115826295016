import Pagination from '../../components/layout/Pagination'
import Photo from '../../components/Photo'
import firstPhoto from '../../assets/photo/schott-wrangler/schott-wrangler-03.jpg'
import secondPhoto from '../../assets/photo/schott-wrangler/schott-wrangler-04.jpg'
import thirdPhoto from '../../assets/photo/schott-wrangler/schott-wrangler-01.jpg'
import fourthPhoto from '../../assets/photo/schott-wrangler/schott-wrangler-02.jpg'
import fifthPhoto from '../../assets/photo/schott-wrangler/schott-wrangler-05.jpg'

function SchottWrangler() {
    return (
        <>
            <div className="contents-wrapper">
                <h2>#Schott × Wrangler</h2>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                    </div>
                </div>
            </div>
            <Pagination nextLink='/photo/overalltokyo' />
        </>
    )
}

export default SchottWrangler