import {Link} from 'react-router-dom'

function  PhotoThumbnail(props) {
    return (
        <>
            <Link to={props.link} className={ props.class}>
                <figure className='fade fadein'>
                    <img src={props.src} alt={props.credit} />
                    <figcaption className='credit'>
                        <p>{ props.credit }</p>
                    </figcaption>
                </figure>
            </Link>
        </>
    )
}

export default  PhotoThumbnail