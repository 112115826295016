import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/chai/chai_002.jpg'
import secondPhoto from '../../../assets/video/chai/chai_001.jpg'
import thirdPhoto from '../../../assets/video/chai/chai_003.jpg'
import fourthPhoto from '../../../assets/video/chai/chai_004.jpg'

function Chai() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ まるごと - "WHOLE"</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/n-fNkTFGxfA" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        CHAI - まるごと - "WHOLE"<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director: Yuka Yamaguchi<br />
                        Producer: Natsumi Ushijima<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/dish/goasatte' />
        </>
    )
}

export default Chai