import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/dish_fourth/IMG_0209.png'
import secondPhoto from '../../../assets/video/dish_fourth/IMG_0210.png'
import thirdPhoto from '../../../assets/video/dish_fourth/IMG_0211.png'
import fourthPhoto from '../../../assets/video/dish_fourth/IMG_0212.png'
import fifthPhoto from '../../../assets/video/dish_fourth/IMG_0213.png'
import sixthPhoto from '../../../assets/video/dish_fourth/IMG_0214.png'
import seventhPhoto from '../../../assets/video/dish_fourth/IMG_0215.png'
import eighthPhoto from '../../../assets/video/dish_fourth/IMG_0216.png'

function DishFourth() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ DISH// - いつだってHIGH!</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/CX3E5UPngig?si=R_p8dvooliHryLBa" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        DISH// - いつだってHIGH!<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Yoshika Matsuoka<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/the-peggies' />
        </>
    )
}

export default DishFourth