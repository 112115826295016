import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/tokyo_tushin_uni/IMG_0067.png'
import secondPhoto from '../../../assets/video/tokyo_tushin_uni/IMG_0068.png'
import thirdPhoto from '../../../assets/video/tokyo_tushin_uni/IMG_0069.png'
import fourthPhoto from '../../../assets/video/tokyo_tushin_uni/IMG_0070.png'

function TokyoTushin() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ 東京通信大学 - TV CM</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/agdpZOeFXLk?si=VgusmQTyJRtKV7Z_" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        東京通信大学 - TV CM<br />
                        「学びと、いい出会いを」篇<br />
                        <br />
                        Director: Yuka Yamaguchi
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/uniqlo' />
        </>
    )
}

export default TokyoTushin