import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/lipps_boy/IMG_0171.png'
import secondPhoto from '../../../assets/video/lipps_boy/IMG_0172.png'
import thirdPhoto from '../../../assets/video/lipps_boy/IMG_0173.png'
import fourthPhoto from '../../../assets/video/lipps_boy/IMG_0174.png'
import fifthPhoto from '../../../assets/video/lipps_boy/IMG_0175.png'
import sixthPhoto from '../../../assets/video/lipps_boy/IMG_0176.png'
import seventhPhoto from '../../../assets/video/lipps_boy/IMG_0177.png'
import eighthPhoto from '../../../assets/video/lipps_boy/IMG_0178.png'

function LippsBoy() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ LIPPS BOY-ブランドムービー「TOKYO STREET STYLING」</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/_jWNIcbt-ac?si=CMk9uUVDRu1GdHa6" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        LIPPS BOY-ブランドムービー「TOKYO STREET STYLING」<br />
                        <br />
                        Director: Taiju Nakajima<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='' />
        </>
    )
}

export default LippsBoy