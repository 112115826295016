import Pagination from '../../components/layout/Pagination'
import Photo from '../../components/Photo'
import firstPhoto from '../../assets/photo/dishup/IMG_0046.jpg'
import secondPhoto from '../../assets/photo/dishup/IMG_0077.jpg'
import thirdPhoto from '../../assets/photo/dishup/IMG_0046.jpg'
import fourthPhoto from '../../assets/photo/dishup/IMG_0210.jpg'
import fifthPhoto from '../../assets/photo/dishup/T5I3332.jpg'
import sixthPhoto from '../../assets/photo/dishup/T5I3585.jpg'
import seventhPhoto from '../../assets/photo/dishup/T5I3902.jpg'

function Dishup() {
    return (
        <>
            <div className="contents-wrapper">
                <h2>#dishup official</h2>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                    </div>
                </div>
            </div>
            <Pagination nextLink='/photo/my-work' />
        </>
    )
}

export default Dishup