import VideoThumbnail from '../../../components/VideoThumbnail'
import FendiTop from '../../../assets/video/fendi/fendi_001.png'
import SuitCompanyTop from '../../../assets/video/suit_company/suit_company_7.png'
import GtuneTop from '../../../assets/video/gtune/gtune_001.png'
import UniqloTop from '../../../assets/video/uniqlo/uniqlo_5.png'
import WoodsTop from '../../../assets/video/woods/woods_03.png'
import KeirinGpTop from '../../../assets/video/keirin_gp/keirin_gp_07.jpg'
import PumaTop from '../../../assets/video/puma/puma_02.jpg'
import AmostyleTop from '../../../assets/video/amostyle/amostyle_01.jpg'
import TatrasTop from '../../../assets/video/tatras/tatras_08.jpg'
import NikeBeyondTop from '../../../assets/video/nike_beyond/nike_beyond_01.png'
import WacoalTop from '../../../assets/video/wacoal/IMG_0047.png'
// import PlatinumWomanTop from '../../../assets/video/platinum_woman/IMG_0060.png'
import TokyoTushinTop from '../../../assets/video/tokyo_tushin_uni/IMG_0070.png'
import VansTop from '../../../assets/video/vans/IMG_0074.png'
import EnsembleStarsTop from '../../../assets/video/ensemble_stars/IMG_0081.png'
import ZozovillaTop from '../../../assets/video/zozovilla/IMG_0090.png'
import LippsBoyTop from '../../../assets/video/lipps_boy/IMG_0174.png'
import CasioGShockTop from '../../../assets/video/casio_gshock/IMG_0271.png'
import JurkTop from '../../../assets/video/jurk_concept_movie/IMG_0277.png'

function Commercial() {
    const fendiInfo = {
        link: '/video/commercial/fendi',
        class: 'video',
        src: FendiTop,
        credit: "◼︎ Harper's BAZAAR - FENDI"
    }
    const suitCompanyInfo = {
        link: '/video/commercial/the-suit-company',
        class: 'video',
        src: SuitCompanyTop,
        credit: "◼︎ THE SUIT COMPANY - 期待しない息子"
    }
    const gtuneInfo = {
        link: '/video/commercial/gtune-detonation-gaming',
        class: 'video',
        src: GtuneTop,
        credit: "◼︎【G-Tune × DetonatioN Gaming】\nThe e-sports colosseum 30秒 | マウスコンピューター"
    }
    const uniqloInfo = {
        link: '/video/commercial/uniqlo-2022fw',
        class: 'video',
        src: UniqloTop,
        credit: "◼︎ ユニクロ - 22FW HEATTECH × 細田守監督"
    }
    const woodsInfo = {
        link: '/video/commercial/woods-2021fw',
        class: 'video',
        src: WoodsTop,
        credit: "◼︎ WOODS - 2021 FW"
    }
    const keirinGpInfo = {
        link: '/video/commercial/keirin-gp-2022',
        class: 'video',
        src: KeirinGpTop,
        credit: "◼︎ KEIRIN グランプリ2022"
    }
    const pumaInfo = {
        link: '/video/commercial/puma',
        class: 'video',
        src: PumaTop,
        credit: "◼︎ PUMA - SUEDE  SLIPSTREAM"
    }
    const amostyleInfo = {
        link: '/video/commercial/amostyle',
        class: 'video',
        src: AmostyleTop,
        credit: "◼︎ AMOSTYLE SS23 PV"
    }
    const tatrasInfo = {
        link: '/video/commercial/tatras',
        class: 'video',
        src: TatrasTop,
        credit: "◼︎ TATRAS Monogram Collection"
    }
    const nikeBeyondInfo = {
        link: '/video/commercial/nike-beyond',
        class: 'video',
        src: NikeBeyondTop,
        credit: "◼︎ Nike - Beyond Shibuya Crossing Takeover"
    }
    const wacoalInfo = {
        link: '/video/commercial/wacoal',
        class: 'video',
        src: WacoalTop,
        credit: "◼︎ Wacoal - TV CM"
    }
    // const platinumWomanInfo = {
    //     link: '/video/commercial/platinum-woman',
    //     class: 'video',
    //     src: PlatinumWomanTop,
    //     credit: '◼︎ プラチナウーマン - brand movie'
    // }
    const tokyoTushinInfo = {
        link: '/video/commercial/tokyo-tushin',
        class: 'video',
        src: TokyoTushinTop,
        credit: '◼︎ 東京通信大学 - TV CM'
    }
    const vansInfo = {
        link: '/video/commercial/vans',
        class: 'video',
        src: VansTop,
        credit: '◼︎ VANS - THIS IS OFF THE WALL'
    }
    const ensembleStarsInfo = {
        link: '/video/commercial/ensemble-stars',
        class: 'video',
        src: EnsembleStarsTop,
        credit: '◼︎ あんさんぶるスターズ！！ - POWER OF YOU'
    }
    const ZozovillaInfo = {
        link: '/video/commercial/zozovilla',
        class: 'video',
        src: ZozovillaTop,
        credit: '◼︎ ZOZOVILLA - New luck? New Wallet.'
    }
    const LippsBoyInfo = {
        link: '/video/commercial/lipps-boy',
        class: 'video',
        src: LippsBoyTop,
        credit: '◼︎ LIPPS BOY-ブランドムービー「TOKYO STREET STYLING」'
    }
    const CasioGShockInfo = {
        link: '/video/commercial/casio-g-shock',
        class: 'video',
        src: CasioGShockTop,
        credit: '◼︎ CASIO G-SHOCK - PRECIOUS HEART SELECTION 2023'
    }
    const jurkInfo = {
        link: '/video/commercial/jurk',
        class: 'video',
        src: JurkTop,
        credit: '◼︎ jurk concept movie - each daily life'
    }

    return (
        <>
            <div className="contents-wrapper">
                <div className="videos-wrapper">
                    <div className="col left-col">
                        <VideoThumbnail {...vansInfo} />
                        {/* <VideoThumbnail {...platinumWomanInfo} /> */}
                        <VideoThumbnail {...nikeBeyondInfo} />
                        <VideoThumbnail {...jurkInfo} />
                        <VideoThumbnail {...pumaInfo} />
                        <VideoThumbnail {...keirinGpInfo} />
                        <VideoThumbnail {...woodsInfo} />
                        <VideoThumbnail {...tokyoTushinInfo} />
                        <VideoThumbnail {...uniqloInfo} />
                        <VideoThumbnail {...LippsBoyInfo} />
                    </div>
                    <div className="col right-col">
                        <VideoThumbnail {...wacoalInfo} />
                        <VideoThumbnail {...fendiInfo} />
                        <VideoThumbnail {...CasioGShockInfo} />
                        <VideoThumbnail {...ensembleStarsInfo} />
                        <VideoThumbnail {...amostyleInfo} />
                        <VideoThumbnail {...suitCompanyInfo} />
                        <VideoThumbnail {...tatrasInfo} />
                        <VideoThumbnail {...ZozovillaInfo} />
                        <VideoThumbnail {...gtuneInfo} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Commercial