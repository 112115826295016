import Pagination from '../../components/layout/Pagination'
import Photo from '../../components/Photo'
import topImage from '../../assets/photo/miske/IMG_0803.jpg'
import firstPhoto from '../../assets/photo/miske/IMG_0134.jpg'
import secondPhoto from '../../assets/photo/miske/IMG_0144.jpg'
import thirdPhoto from '../../assets/photo/miske/IMG_0168.jpg'
import fourthPhoto from '../../assets/photo/miske/IMG_0456.jpg'

function Miske() {
    return (
        <>
            <div className="contents-wrapper">
                <h2>#MISKE</h2>
                <div className="top-image-container">
                    <img src={topImage} alt="top" />
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                    </div>
                </div>
            </div>
            <Pagination nextLink='/photo/pmd' />
        </>
    )
}

export default Miske