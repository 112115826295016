import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/tatsuro_yamashita/IMG_0262.png'
import secondPhoto from '../../../assets/video/tatsuro_yamashita/IMG_0263.png'
import thirdPhoto from '../../../assets/video/tatsuro_yamashita/IMG_0264.png'
import fourthPhoto from '../../../assets/video/tatsuro_yamashita/IMG_0265.png'
import fifthPhoto from '../../../assets/video/tatsuro_yamashita/IMG_0266.png'
import sixthPhoto from '../../../assets/video/tatsuro_yamashita/IMG_0267.png'
import seventhPhoto from '../../../assets/video/tatsuro_yamashita/IMG_0268.png'
import eighthPhoto from '../../../assets/video/tatsuro_yamashita/IMG_0269.png'

function TatsuroYamashita() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ 山下達郎 - SPARKLE</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/pqobRu9aR3M?si=vfSWg7c-ty1HbGdj" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        山下達郎 - SPARKLE<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Katsuki Kuroyanagi<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/chilldspot' />
        </>
    )
}

export default TatsuroYamashita