import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/casio_gshock/IMG_0271.png'
import secondPhoto from '../../../assets/video/casio_gshock/IMG_0272.png'
import thirdPhoto from '../../../assets/video/casio_gshock/IMG_0273.png'
import fourthPhoto from '../../../assets/video/casio_gshock/IMG_0274.png'
import fifthPhoto from '../../../assets/video/casio_gshock/IMG_0275.png'
import sixthPhoto from '../../../assets/video/casio_gshock/IMG_0276.png'

function CasioGShock() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ CASIO G-SHOCK - PRECIOUS HEART SELECTION 2023</h2>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        CASIO G-SHOCK - PRECIOUS HEART SELECTION 2023<br />
                        <br />
                        Director: Ayaka Yamashiro<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/tokyo-tushin' />
        </>
    )
}

export default CasioGShock