import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/skyhi_nissy/IMG_0245.png'
import secondPhoto from '../../../assets/video/skyhi_nissy/IMG_0246.png'
import thirdPhoto from '../../../assets/video/skyhi_nissy/IMG_0248.png'
import fourthPhoto from '../../../assets/video/skyhi_nissy/IMG_0249.png'
import fifthPhoto from '../../../assets/video/skyhi_nissy/IMG_0250.png'
import sixthPhoto from '../../../assets/video/skyhi_nissy/IMG_0251.png'
import seventhPhoto from '../../../assets/video/skyhi_nissy/IMG_0252.png'
import eighthPhoto from '../../../assets/video/skyhi_nissy/IMG_0253.png'

function SkyHiNissy() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ SKY-HI×Nissy - SUPER IDOL</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/XUrR5hxfdM8?si=5zo6iPUh_RgOT7YO" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        SKY-HI×Nissy - SUPER IDOL<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Daiki Kamoshita<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/chilldspot' />
        </>
    )
}

export default SkyHiNissy