import { Link} from 'react-router-dom'
import InstagramIcon from '../../assets/insta_logo.png'


function Header() {
  return (
    <>
      <header>
        <div className="header-left w-[55%] pt-[9vh]">
          <Link to='/'>
            <h1>SHUHO TERAMURA</h1>
          </Link>
        </div>
        <div className="header-right w-[45%] pt-[9vh]">
          <ul className='flex items-center justify-end font-bold'>
            <li className='menu'>
              <Link to='/photography' className='inline-block w-full h-8 leading-8'>Photography</Link>
            </li>
            <li className='menu dropdown dropdown-hover'>
              <label tabIndex={0} className='inline-block w-full h-8 leading-8 relative'>Videos</label>
                <ul tabIndex={0} className='dropdown-content menu p-2 shadow bg-[#f2efe4] rounded-box w-auto absolute top-8 overflow-y-visible relative'>
                  <li><Link to='/video/commercial'>Commercial</Link></li>
                  <li><Link to='/video/music-video'>MusicVideo</Link></li>
                </ul>
            </li>
            <li className='menu'>
              <a href="mailto:&#116;&#101;&#114;&#97;&#109;&#117;&#114;&#97;&#115;&#104;&#117;&#104;&#111;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;">
                contact
              </a>
            </li>
            <li>
              <a href='https://www.instagram.com/shuho_teramura/' target="_blank" rel="noopener noreferrer" className='h-8 leading-8'>
                <img src={InstagramIcon} alt='Instagram logo' className='h-[20px] max-w-none' />
              </a>
            </li>
          </ul>
        </div>
      </header>
    </>
  )
}

export default Header