import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/tomoo_present/IMG_0181.png'
import secondPhoto from '../../../assets/video/tomoo_present/IMG_0182.png'
import thirdPhoto from '../../../assets/video/tomoo_present/IMG_0183.png'
import fourthPhoto from '../../../assets/video/tomoo_present/IMG_0184.png'
import fifthPhoto from '../../../assets/video/tomoo_present/IMG_0185.png'
import sixthPhoto from '../../../assets/video/tomoo_present/IMG_0186.png'
import seventhPhoto from '../../../assets/video/tomoo_present/IMG_0187.png'
import eighthPhoto from '../../../assets/video/tomoo_present/IMG_0189.png'

function TomooFirst() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ TOMOO - present</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/EvpsZde4QSI?si=kb_JdSdSb1P9mqe1" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        TOMOO - present<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Masaki Watanabe<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/nogizaka46' />
        </>
    )
}

export default TomooFirst