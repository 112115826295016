import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/atarayo/atarayo_002.jpg'
import secondPhoto from '../../../assets/video/atarayo/atarayo_001.jpg'
import thirdPhoto from '../../../assets/video/atarayo/atarayo_003.jpg'
import fourthPhoto from '../../../assets/video/atarayo/atarayo_005.jpg'
import fifthPhoto from '../../../assets/video/atarayo/atarayo_004.jpg'
import sixthPhoto from '../../../assets/video/atarayo/atarayo_006.jpg'

function Atarayo() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ 青を掬う</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/B9d2RMJP0e0" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        あたらよ - 青を掬う<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director: Kei Murotani<br />
                        Producer: Yosuke Yamaguchi<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='' />
        </>
    )
}

export default Atarayo