import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/ao/ao_1.jpg'
import secondPhoto from '../../../assets/video/ao/ao_2.jpg'
import thirdPhoto from '../../../assets/video/ao/ao_3.jpg'
import fourthPhoto from '../../../assets/video/ao/ao_4.jpg'
import fifthPhoto from '../../../assets/video/ao/ao_5.jpg'
import sixthPhoto from '../../../assets/video/ao/ao_6.jpg'
import seventhPhoto from '../../../assets/video/ao/ao_7.jpg'
import eighthPhoto from '../../../assets/video/ao/ao_8.jpg'

function Ao() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ 余所見</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/xEhhRx_KsGg" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        ao - 余所見<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        DIRCTOR: Takumi Gunji<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/the-peggies' />
        </>
    )
}

export default Ao