import PhotographyTop from '../../assets/photo/sternberg-ss/sternberg-fw-09.jpg'
import PhotoThumbnail from '../../components/PhotoThumbnail'
import SchottDickiesTop from '../../assets/photo/schott-dickies/schott-dickies-03.jpg'
import SternbergSSTop from '../../assets/photo/sternberg-ss/sternberg-fw-09.jpg'
import MiskeTop from '../../assets/photo/miske/IMG_0803.jpg'
import PmdTop from '../../assets/photo/pmd/T5I0021.jpg'
import DishupTop from '../../assets/photo/dishup/IMG_0077.jpg'
import SchottWranglerTop from '../../assets/photo/schott-wrangler/schott-wrangler-03.jpg'
import OverAllTokyoTop from '../../assets/photo/overall/000023.jpg'
import LimTokyoTop from '../../assets/photo/limtokyo/T5I5680.jpg'
import MyWorkTop from '../../assets/mywork/IMG_0490.jpg'

function Photography() {

    const schottDickiesInfo = {
        link: '/photo/schott-dickies',
        class: 'photo',
        src: SchottDickiesTop,
        credit: 'Schott × Dickies'
    }
    const sternbergSSInfo = {
        link: '/photo/sternberg-ss',
        class: 'photo',
        src: SternbergSSTop,
        credit: 'STERNBERG - 2022 SS'
    }
    const miskeInfo = {
        link: '/photo/miske',
        class: 'photo',
        src: MiskeTop,
        credit: 'BRAND @ MISKE'
    }
    const PmdInfo = {
        link: '/photo/pmd',
        class: 'photo',
        src: PmdTop,
        credit: 'BRAND @ PMD'
    }
    const DishupInfo = {
        link: '/photo/dishup',
        class: 'photo',
        src: DishupTop,
        credit: 'BRAND @ DISHUP TOKYO'
    }
    const schottWranglerInfo = {
        link: '/photo/schott-wrangler',
        class: 'photo',
        src: SchottWranglerTop,
        credit: 'Schott × Wrangler'
    }
    const overAllTokyoInfo = {
        link: '/photo/overalltokyo',
        class: 'photo',
        src: OverAllTokyoTop,
        credit: 'BRAND @ OVER ALL TOKYO'
    }
    const limTokyoInfo = {
        link: '/photo/limtokyo',
        class: 'photo',
        src: LimTokyoTop,
        credit: 'BRAND @ LIM TOKYO'
    }
    const myWorkInfo = {
        link: '/photo/my-work',
        class: 'photo',
        src: MyWorkTop,
        credit: 'MY WORK'
    }

    return (
        <>
            <div className='photo-page-wrapper w-full'>
                <div className="top-image-container">
                    <img src={PhotographyTop} alt="Top" />
                </div>
                <div className="photo-wrapper">
                    <div className="col left-col">
                        <PhotoThumbnail {...schottDickiesInfo} />
                        <PhotoThumbnail {...sternbergSSInfo} />
                        <PhotoThumbnail {...miskeInfo} />
                        <PhotoThumbnail {...PmdInfo} />
                        <PhotoThumbnail {...DishupInfo} />
                    </div>
                    <div className="col right-col">
                        <PhotoThumbnail {...schottWranglerInfo} />
                        <PhotoThumbnail {...overAllTokyoInfo} />
                        <PhotoThumbnail {...limTokyoInfo} />
                        <PhotoThumbnail {...myWorkInfo} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Photography