import React from 'react'

function Footer() {
  return (
    <>
      <footer>
        <p>Copyright©️ShuhoTeramura All Rights Reserved.</p>
      </footer>
    </>
  )
}

export default Footer