import Pagination from '../../components/layout/Pagination'
import Photo from '../../components/Photo'
import topImage from '../../assets/photo/sternberg-ss/sternberg-fw-09.jpg'
import firstPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-00.jpg'
import secondPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-03.jpg'
import thirdPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-01.jpg'
import fourthPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-04.jpg'
import fifthPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-10.jpg'
import sixthPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-05.jpg'
import seventhPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-09.jpg'
import eighthPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-11.jpg'
import ninthPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-08.jpg'
import tenthPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-07.jpg'
import eleventhPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-06.jpg'
import twelfthPhoto from '../../assets/photo/sternberg-ss/sternberg-fw-02.jpg'

function SternbergSS() {
    return (
        <>
            <div className="contents-wrapper">
                <h2>#STERNBERG - 2022 SS</h2>
                <div className="top-image-container">
                    <img src={topImage} alt="top" />
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={eighthPhoto} />
                        <Photo src={tenthPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={seventhPhoto} />
                        <Photo src={ninthPhoto} />
                        <Photo src={eleventhPhoto} />
                        <Photo src={twelfthPhoto} />
                    </div>
                </div>
            </div>
            <Pagination nextLink='/photo/limtokyo' />
        </>
    )
}

export default SternbergSS