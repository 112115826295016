import TopImage from '../assets/top_image.jpg'

function Top() {
    return (
        <>
            <div className='z-0 w-full h-[81.2vw]'>
                <div className='w-full h-[81.2%] my-0 mx-auto'>
                    <img src={TopImage} alt="top page" className='w-full h-full object-cover' />
                </div>
            </div>
        </>
    )
}

export default Top