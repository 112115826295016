import {Link} from 'react-router-dom'

function VideoThumbnail(props) {
  return (
    <>
      <Link to={props.link} className={props.class}>
        <label className='credit'>{ props.credit }</label>
        <figure className='fade fadein'>
          <img src={props.src} alt={props.credit} />
        </figure>
      </Link>
    </>

  )
}

export default VideoThumbnail