import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/chilli_beans/IMG_0191.png'
import secondPhoto from '../../../assets/video/chilli_beans/IMG_0192.png'
import thirdPhoto from '../../../assets/video/chilli_beans/IMG_0193.png'
import fourthPhoto from '../../../assets/video/chilli_beans/IMG_0195.png'
import fifthPhoto from '../../../assets/video/chilli_beans/IMG_0196.png'
import sixthPhoto from '../../../assets/video/chilli_beans/IMG_0197.png'
import seventhPhoto from '../../../assets/video/chilli_beans/IMG_0198.png'
import eighthPhoto from '../../../assets/video/chilli_beans/IMG_0199.png'

function ChilliBeans() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ Chilli Beans - 105☺</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/L55PsssNH7Q?si=joXXGdCIFJso7qPF" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        Chilli Beans - 105☺<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Daiki Kamoshita<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/chilldspot' />
        </>
    )
}

export default ChilliBeans