import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/nulbarich/1A.png'
import secondPhoto from '../../../assets/video/nulbarich/1D.png'
import thirdPhoto from '../../../assets/video/nulbarich/1G.png'
import fourthPhoto from '../../../assets/video/nulbarich/2A.png'
import fifthPhoto from '../../../assets/video/nulbarich/3C.png'
import sixthPhoto from '../../../assets/video/nulbarich/3I.png'
import seventhPhoto from '../../../assets/video/nulbarich/NULBARICH_DAY_SHIRO.00_03_44_15.Still036.png'
import eighthPhoto from '../../../assets/video/nulbarich/NULBARICH_DAY_SHIRO.00_04_14_12.Still037.png'

function Nulbarich() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ Nulbarich - DAY feat. PUNPEE</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/NEtR5dtORn0?si=hO8TzbdIjW00Oji2" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        Nulbarich - DAY feat. PUNPEE<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Masaki Watanabe<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/badhop' />
        </>
    )
}

export default Nulbarich