import { Link } from 'react-router-dom'
import Photo from '../../components/Photo'
import topImage from '../../assets/mywork/IMG_0490.jpg'
import firstPhoto from '../../assets/mywork/IMG_0343.jpg'
import secondPhoto from '../../assets/mywork/IMG_0073.jpg'
import thirdPhoto from '../../assets/mywork/IMG_0401.jpg'
import fourthPhoto from '../../assets/mywork/IMG_0124.jpg'
import fifthPhoto from '../../assets/mywork/IMG_0087.jpg'
import sixthPhoto from '../../assets/mywork/IMG_0015-2.jpg'
import seventhPhoto from '../../assets/mywork/T5I8072.jpg'
import eighthPhoto from '../../assets/mywork/IMG_0025.jpg'
import ninthPhoto from '../../assets/mywork/2_B_LIGHT.jpg'

function MyWork() {
    return (
        <>
            <div className="contents-wrapper">
                <h2>MY WORK</h2>
                <div className="top-image-container">
                    <img src={topImage} alt="top" />
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                        <Photo src={ninthPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
            </div>
            <div className='pagination-wrapper'>
                <div className='page-button previous'>
                    <Link to='/'>
                        <div className='triangle pre-triangle'></div>
                        <h2>TOP</h2>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default MyWork