import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/ensemble_stars/IMG_0081.png'
import secondPhoto from '../../../assets/video/ensemble_stars/IMG_0082.png'
import thirdPhoto from '../../../assets/video/ensemble_stars/IMG_0083.png'
import fourthPhoto from '../../../assets/video/ensemble_stars/IMG_0084.png'
import fifthPhoto from '../../../assets/video/ensemble_stars/IMG_0085.png'
import sixthPhoto from '../../../assets/video/ensemble_stars/IMG_0086.png'
import seventhPhoto from '../../../assets/video/ensemble_stars/IMG_0087.png'
import eighthPhoto from '../../../assets/video/ensemble_stars/IMG_0088.png'

function EnsembleStars() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ あんさんぶるスターズ！！ - POWER OF YOU</h2>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        あんさんぶるスターズ！！<br />
                        POWER OF YOU<br />
                        <br />
                        Director: Denki Imahara<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/amostyle' />
        </>
    )
}

export default EnsembleStars