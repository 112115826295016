import Pagination from '../../../components/layout/Pagination'
import Photo from '../../../components/Photo'
import firstPhoto from '../../../assets/video/keirin_gp/keirin_gp_01.jpg'
import secondPhoto from '../../../assets/video/keirin_gp/keirin_gp_02.jpg'
import thirdPhoto from '../../../assets/video/keirin_gp/keirin_gp_03.jpg'
import fourthPhoto from '../../../assets/video/keirin_gp/keirin_gp_04.jpg'
import fifthPhoto from '../../../assets/video/keirin_gp/keirin_gp_05.jpg'
import sixthPhoto from '../../../assets/video/keirin_gp/keirin_gp_06.jpg'
import seventhPhoto from '../../../assets/video/keirin_gp/keirin_gp_07.jpg'
import eighthPhoto from '../../../assets/video/keirin_gp/keirin_gp_08.jpg'

function KeirinGp() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ KEIRIN グランプリ2022【TV-CM】</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/hFf7HWJKVGo" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        【TV-CM】<br />
                        KEIRIN グランプリ2022<br />
                        <br />
                        Director : Daiki Kamoshita<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/commercial/the-suit-company' />
        </>
    )
}

export default KeirinGp