import Pagination from '../../components/layout/Pagination'
import Photo from '../../components/Photo'
import topImage from '../../assets/photo/overall/000032.jpg'
import firstPhoto from '../../assets/photo/overall/000013.jpg'
import secondPhoto from '../../assets/photo/overall/000027.jpg'
import thirdPhoto from '../../assets/photo/overall/000033.jpg'
import fourthPhoto from '../../assets/photo/overall/000023.jpg'
import fifthPhoto from '../../assets/photo/overall/000019.jpg'
import sixthPhoto from '../../assets/photo/overall/000015.jpg'
import seventhPhoto from '../../assets/photo/overall/000020.jpg'
import eighthPhoto from '../../assets/photo/overall/000016.jpg'
import ninthPhoto from '../../assets/photo/overall/000009.jpg'
import tenthPhoto from '../../assets/photo/overall/000012.jpg'
import eleventhPhoto from '../../assets/photo/overall/000003.jpg'
import twelfthPhoto from '../../assets/photo/overall/000014.jpg'
import thirteenthPhoto from '../../assets/photo/overall/000011-2.jpg'
import fourteenthPhoto from '../../assets/photo/overall/000017.jpg'

function OverAllTokyo() {
    return (
        <>
            <div className="contents-wrapper">
                <h2>#OVER ALL TOKYO</h2>
                <div className="top-image-container">
                    <img src={topImage} alt="top" />
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                        <Photo src={ninthPhoto} />
                        <Photo src={eleventhPhoto} />
                        <Photo src={thirteenthPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                        <Photo src={tenthPhoto} />
                        <Photo src={twelfthPhoto} />
                        <Photo src={fourteenthPhoto} />
                    </div>
                </div>
                <div className="credit-wrapper">
                    <p>
                        BRAND @overalltokyo
                        @masato_coldrain<br />
                        <br />
                        Cast<br />
                        Task @l_act_task<br />
                        Lyla Iwamoto (Hirata Office) @lylaa05<br />
                        <br />
                        Directer<br />
                        Endou Kazuki (five:pm) @kzk1018<br />
                        <br />
                        Producer<br />
                        Matlas Maehama (SKID) @matlasmaehama<br />
                        <br />
                        Production Manager<br />
                        YOIHIRO (SKID) @yoichi000<br />
                        <br />
                        Director of Photography<br />
                        Koya Yokoyama (five:pm) @koya__411<br />
                        <br />
                        Photographer<br />
                        Shuho Teramura @shuho_teramura<br />
                        <br />
                        1st AC<br />
                        Yuki Kobayashi @punkyu
                    </p>
                    <p>
                        Gaffer<br />
                        Ryo Ueda @r.y.o.1.1.0.7<br />
                        <br />
                        Lighting Assistant<br />
                        Akira Hayakawa (five:pm) @akirashady<br />
                        Shun Mate<br />
                        <br />
                        Atylist<br />
                        Sho Sasaki @shosasaki_<br />
                        <br />
                        Hair&Makeup Artist<br />
                        Noki Imajo @naoki_imajo<br />
                        <br />
                        Special Thanks<br />
                        Kazuma Asami (SKID) @igotagoodwallet<br />
                        TAIGA (SKID) @tgafim<br />
                        Dai Wako<br />
                        <br />
                        Colorist<br />
                        Yoshiyuki Nishida @yn.mcf<br />
                        <br />
                        Music, Lylics &Narration<br />
                        Kyoichi Mikuriya (SKID) @mickmickmick6928
                    </p>
                    <p>
                        Recording Engineer<br />
                        Dr.Pay (SKID.ASILIS) @dr__pay<br />
                        <br />
                        thanks<br />
                        @popeye_camera
                    </p>
                </div>
            </div>
            <Pagination nextLink='/photo/sternberg-ss' />
        </>
    )
}

export default OverAllTokyo