import { Link } from 'react-router-dom'

function Pagination(props) {
    return (
        <>
            <div className='pagination-wrapper'>
                <div className='page-button previous'>
                    <Link to='/'>
                        <div className='triangle pre-triangle'></div>
                        <h2>TOP</h2>
                    </Link>
                </div>
                {props.nextLink !== '' ?
                    <div className='page-button next'>
                        <Link to={props.nextLink}>
                            <h2>NEXT</h2>
                            <div className='triangle next-triangle'></div>
                        </Link>
                    </div> :
                    <></>
                }
            </div>
        </>
    )
}

export default Pagination