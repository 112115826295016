import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/tomoo_second/IMG_0254.png'
import secondPhoto from '../../../assets/video/tomoo_second/IMG_0255.png'
import thirdPhoto from '../../../assets/video/tomoo_second/IMG_0256.png'
import fourthPhoto from '../../../assets/video/tomoo_second/IMG_0257.png'
import fifthPhoto from '../../../assets/video/tomoo_second/IMG_0258.png'
import sixthPhoto from '../../../assets/video/tomoo_second/IMG_0259.png'
import seventhPhoto from '../../../assets/video/tomoo_second/IMG_0260.png'
import eighthPhoto from '../../../assets/video/tomoo_second/IMG_0261.png'

function TomooSecond() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ TOMOO - Super Ball</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/SzguJI6S9V4?si=jXzv3qvD-hrZOdAi" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                        <Photo src={seventhPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                        <Photo src={eighthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        TOMOO - Super Ball<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Tai Nakazawa<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/chilldspot' />
        </>
    )
}

export default TomooSecond