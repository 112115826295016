import Pagination from "../../../components/layout/Pagination"
import Photo from "../../../components/Photo"
import firstPhoto from '../../../assets/video/dish_third/dish_third_01.png'
import secondPhoto from '../../../assets/video/dish_third/dish_third_02.png'
import thirdPhoto from '../../../assets/video/dish_third/dish_third_03.png'
import fourthPhoto from '../../../assets/video/dish_third/dish_third_04.png'
import fifthPhoto from '../../../assets/video/dish_third/dish_third_05.png'
import sixthPhoto from '../../../assets/video/dish_third/dish_third_06.png'

function DishThird() {
    return (
        <>
            <div className="contents-wrapper">
                <h2 className='font-sawarabiMincho'>◼︎ 万々歳</h2>
                <div className='top-video-container'>
                    <iframe width="100%" src="https://www.youtube.com/embed/sA5thqqF4yY" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className='photo-wrapper'>
                    <div className='col left-col'>
                        <Photo src={firstPhoto} />
                        <Photo src={thirdPhoto} />
                        <Photo src={fifthPhoto} />
                    </div>
                    <div className='col right-col'>
                        <Photo src={secondPhoto} />
                        <Photo src={fourthPhoto} />
                        <Photo src={sixthPhoto} />
                    </div>
                </div>
                <div className='credit-wrapper'>
                    <p>
                        DISH// - 万々歳<br />
                        <br />
                        ~ Official Video ~<br />
                        <br />
                        Director : Shun Murakami<br />
                        <br />
                    </p>
                </div>
            </div>
            <Pagination nextLink='/video/music-video/cent' />
        </>
    )
}

export default DishThird