import VideoThumbnail from '../../../components/VideoThumbnail'
import RyokushakaTop from '../../../assets/video/ryokushaka/ryokushaka_3.png'
import DishSecondTop from '../../../assets/video/dish_second/dish_second_7.png'
import DishTop from '../../../assets/video/dish/dish_02.jpg'
import IkutaRiraTop from '../../../assets/video/rira_ikuta/rira_ikuta_6.png'
import ChilldspotTop from '../../../assets/video/chilldspot/chilldspot_3.jpg'
import ChaiTop from '../../../assets/video/chai/chai_001.jpg'
import PeggiesTop from '../../../assets/video/peggies/peggies_03.png'
import MaliyaTop from '../../../assets/video/maliya/maliya_001.png'
import ToyamaMireiTop from '../../../assets/video/mirei_toyama/mirei_toyama_6.png'
import AoTop from '../../../assets/video/ao/ao_4.jpg'
import KanaboonTop from '../../../assets/video/kanaboon/kanaboon_8.png'
import AtarayoTop from '../../../assets/video/atarayo/atarayo_001.jpg'
import TokyoRaveGroupieTop from '../../../assets/video/tokyoravegroupie/tokyoravegroupie_001.png'
import CentTop from '../../../assets/video/cent/cent_02.jpg'
import DishThirdTop from '../../../assets/video/dish_third/dish_third_06.png'
import NogizakaTop from '../../../assets/video/nogizaka46/nogizaka_08.jpg'
import MmncTop from '../../../assets/video/mmnc/mmnc_01.jpg'
import TomooFirstTop from '../../../assets/video/tomoo_present/IMG_0181.png'
import ChilliBeansTop from '../../../assets/video/chilli_beans/IMG_0191.png'
import BadHopTop from '../../../assets/video/badhop/IMG_0200.png'
import DishFourthTop from '../../../assets/video/dish_fourth/IMG_0210.png'
import NulbarichTop from '../../../assets/video/nulbarich/2A2.jpg'
import GoGoVanillasTop from '../../../assets/video/go_go_vanillas/IMG_0233.png'
import OfficialHigeDandismTop from '../../../assets/video/official_hige_dan_dism/IMG_0237.png'
import SkyHiNissyTop from '../../../assets/video/skyhi_nissy/IMG_0245_min.png'
import TomooSecondTop from '../../../assets/video/tomoo_second/IMG_0255.png'
import TatsuroYamashitaTop from '../../../assets/video/tatsuro_yamashita/IMG_0262.png'

function MusicVideo() {
    const ryokushakaInfo = {
        link: '/video/music-video/ryokuoushokushakai',
        class: 'video',
        src: RyokushakaTop,
        credit: '◼︎ 緑黄色社会 - ミチヲユケ'
    }
    const dishSecondInfo = {
        link: '/video/music-video/dish/goasatte',
        class: 'video',
        src: DishSecondTop,
        credit: '◼︎ DISH// - 五明後日'
    }
    const dishFirstInfo = {
        link: '/video/music-video/dish/chinchouge',
        class: 'video',
        src: DishTop,
        credit: '◼︎ DISH// - 沈丁花'
    }
    const ikutaRiraInfo = {
        link: '/video/music-video/ikutarira',
        class: 'video',
        src: IkutaRiraTop,
        credit: '◼︎ 幾田りら - スパークル'
    }
    const chilldspotInfo = {
        link: '/video/music-video/chilldspot',
        class: 'video',
        src: ChilldspotTop,
        credit: ' ◼︎ chilldspot - shower'
    }
    const chaiInfo = {
        link: '/video/music-video/chai',
        class: 'video',
        src: ChaiTop,
        credit: ' ◼︎ CHAI - まるごと - "WHOLE" - Official Music Video'
    }
    const peggiesInfo = {
        link: '/video/music-video/the-peggies',
        class: 'video',
        src: PeggiesTop,
        credit: '◼︎ the peggies - ドラマチック'
    }
    const maliyaInfo = {
        link: '/video/music-video/maliya',
        class: 'video',
        src: MaliyaTop,
        credit: '◼︎ MALIYA - Everybody Needs Love feat.SIRUP\n(Prod. Island State Music)'
    }
    const tokyoRaveGroupieInfo = {
        link: '/video/music-video/tokyoravegroupie',
        class: 'video',
        src: TokyoRaveGroupieTop,
        credit: '◼︎ TOKYO RAVE GROUPIE - Easy Lazy'
    }
    const toyamaMireiInfo = {
        link: '/video/music-video/toyamamirei',
        class: 'video',
        src: ToyamaMireiTop,
        credit: '◼︎ 當山みれい - sugar spot'
    }
    const aoInfo = {
        link: '/video/music-video/ao',
        class: 'video',
        src: AoTop,
        credit: '◼︎ ao - 余所見'
    }
    const kanaboonInfo = {
        link: '/video/music-video/kana-boon',
        class: 'video',
        src: KanaboonTop,
        credit: '◼︎ KANA-BOON - きらりらり'
    }
    const atarayoInfo = {
        link: '/video/music-video/atarayo',
        class: 'video',
        src: AtarayoTop,
        credit: '◼︎ あたらよ - 青を掬う(Music Video)'
    }
    const centInfo = {
        link: '/video/music-video/cent',
        class: 'video',
        src: CentTop,
        credit: '◼︎ CENT - すてきな予感'
    }
    const dishThirdInfo = {
        link: '/video/music-video/dish/banbanzai',
        class: 'video',
        src: DishThirdTop,
        credit: '◼︎ DISH// - 万々歳'
    }
    const nogizakaInfo = {
        link: '/video/music-video/nogizaka46',
        class: 'video',
        src: NogizakaTop,
        credit: '◼︎ 乃木坂46 - ここにはないもの'
    }
    const mmncInfo = {
        link: '/video/music-video/mmnc',
        class: 'video',
        src: MmncTop,
        credit: '◼︎ Monthly Mu & New Caledonia - night out'
    }
    const tomooFirstInfo = {
        link: '/video/music-video/tomoo/present',
        class: 'video',
        src: TomooFirstTop,
        credit: '◼︎ TOMOO - present'
    }
    const chilliBeansInfo = {
        link: '/video/music-video/chillibeans',
        class: 'video',
        src: ChilliBeansTop,
        credit: '◼︎ Chilli Beans - 105☺'
    }
    const badHopInfo = {
        link: '/video/music-video/badhop',
        class: 'video',
        src: BadHopTop,
        credit: '◼︎ BAD HOP - Last Party Never End'
    }
    const dishFourthInfo = {
        link: '/video/music-video/dish/itsudatte-high',
        class: 'video',
        src: DishFourthTop,
        credit: '◼︎ DISH// - いつだってHIGH!'
    }
    const nulbarichInfo = {
        link: '/video/music-video/nulbarich',
        class: 'video',
        src: NulbarichTop,
        credit: '◼︎ Nulbarich - DAY feat. PUNPEE'
    }
    const goGoVanillasInfo = {
        link: '/video/music-video/go-go-vanillas',
        class: 'video',
        src: GoGoVanillasTop,
        credit: '◼︎ go!go!vanillas - SHAKE'
    }
    const officialHigeDanDismInfo = {
        link: '/video/music-video/official-hige-dan-dism',
        class: 'video',
        src: OfficialHigeDandismTop,
        credit: '◼︎ Official髭男dism - 日常'
    }
    const skyHiNissyInfo = {
        link: '/video/music-video/skyhi-nissy',
        class: 'video',
        src: SkyHiNissyTop,
        credit: '◼︎ SKY-HI×Nissy - SUPER IDOL'
    }
    const tomooSecondInfo = {
        link: '/video/music-video/tomoo/superball',
        class: 'video',
        src: TomooSecondTop,
        credit: '◼︎ TOMOO - Super Ball'
    }
    const tatsuroYamashitaInfo = {
        link: '/video/music-video/tatsuro-yamashita/sparkle',
        class: 'video',
        src: TatsuroYamashitaTop,
        credit: '◼︎ 山下達郎 - SPARKLE'
    }

    return (
        <>
            <div className="contents-wrapper">
                <div className="videos-wrapper">
                    <div className="col left-col">
                        <VideoThumbnail {...tatsuroYamashitaInfo} />
                        <VideoThumbnail {...badHopInfo} />
                        <VideoThumbnail {...goGoVanillasInfo} />
                        <VideoThumbnail {...officialHigeDanDismInfo} />
                        <VideoThumbnail {...chilliBeansInfo} />
                        <VideoThumbnail {...nogizakaInfo} />
                        <VideoThumbnail {...ryokushakaInfo} />
                        <VideoThumbnail {...dishSecondInfo} />
                        <VideoThumbnail {...dishFirstInfo} />
                        <VideoThumbnail {...ikutaRiraInfo} />
                        <VideoThumbnail {...peggiesInfo} />
                        <VideoThumbnail {...tokyoRaveGroupieInfo} />
                        <VideoThumbnail {...centInfo} />
                    </div>
                    <div className="col right-col">
                        <VideoThumbnail {...nulbarichInfo} />
                        <VideoThumbnail {...skyHiNissyInfo} />
                        <VideoThumbnail {...chilldspotInfo} />
                        <VideoThumbnail {...tomooFirstInfo} />
                        <VideoThumbnail {...tomooSecondInfo} />
                        <VideoThumbnail {...chaiInfo} />
                        <VideoThumbnail {...maliyaInfo} />
                        <VideoThumbnail {...dishFourthInfo} />
                        <VideoThumbnail {...toyamaMireiInfo} />
                        <VideoThumbnail {...aoInfo} />
                        <VideoThumbnail {...kanaboonInfo} />
                        <VideoThumbnail {...dishThirdInfo} />
                        <VideoThumbnail {...mmncInfo} />
                        <VideoThumbnail {...atarayoInfo} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MusicVideo